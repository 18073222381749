<template>
  <!--header section start-->
  <header class="header">
    <!--start navbar-->
    <nav class="navbar navbar-expand-lg fixed-top bg-transparent">
      <div class="container">
        <a class="navbar-brand" href="/">
          <img src="../assets/img/logo.png" width="100" height="100" alt="logo" class="img-fluid logo-sm"/>
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="ti-menu"></span>
        </button>
        <div class="collapse navbar-collapse h-auto" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto menu">
            <li class="dropdown"><a href="#"> Home</a>
            </li>
            <li><a href="#about" class="page-scroll">About us</a></li>
            <li><a href="#services" class="page-scroll">Services</a></li>
            <li><a href="#contact" class="page-scroll">Contact Us</a></li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
  <!--header section end-->
</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped>
@media only screen and (max-width: 640px) {
  .logo-sm {
    height: 32px !important;
    width: auto !important;
  }
}
</style>