<template>
  <!--body content wrap start-->
  <div class="main">

    <!--hero section start-->
    <section class="hero-equal-height gradient-overlay pt-100 pb-5 section-top-sm" id="home"
    >
      <div class="container">
        <div class="row align-items-center justify-content-between">
          <div class="col-md-7 col-lg-6">
            <div class="hero-content-left position-relative z-index text-white my-lg-0 my-md-5 my-sm-5 my-5">
              <h1 class="text-white">Building smart digital connections.</h1>
              <p class="lead">Modernizing African businesses and their processes for improved service delivery.</p>

              <a href="#contact" class="btn solid-white-btn animated-btn">Contact with us</a>

            </div>
          </div>
          <div class="col-md-5 col-lg-6">
            <div class="hero-img-right text-center position-relative z-index">
              <img src="../assets/img/smart1.png" alt="app" class="floating img-fluid"/>
            </div>
          </div>
        </div>
      </div>
      <div class="shape-bottom">
        <img src="../assets/img/hero-shape-bottom.svg" alt="shape" class="bottom-shape img-fluid">
      </div>
    </section>
    <!--hero section end-->
    <section class="pt-100" id="about">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col-md-7 col-lg-7">
            <div class="img-wrap" data-aos="fade-right"
                 data-aos-offset="300"
                 data-aos-easing="ease-in-sine">
              <img src="../assets/img/Hardwebb.png" alt="how work" class="img-fluid"/>
            </div>
          </div>
          <div class="col-md-5 col-lg-5">
            <div class="promo-content-wrap">
              <h2>About us</h2>
              <p class="lead">Hardweb is a digital systems development company. Our mission is to modernize African
                businesses and their processes for improved service delivery.</p>

              <!--              <a href="#" class="btn solid-btn animated-btn">Learn More About Us</a>-->
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--promo neb secion start-->
    <section class="promo-new-section pt-100" id="services">
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-lg-8">
            <div class="section-heading mb-5">
              <h2>Our core interests</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-lg-4">
            <div
                class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100 border-radius-7"
                data-aos="fade-up" data-aos-duration="5000">
              <div class="circle-icon">
                <img src="../assets/icons/garbage.png" width="80" alt="promo" class="img-fluid"/>
              </div>
              <h5>Smart waste management systems</h5>
              <p>Monetize your trash with our smart waste management system.</p>
            </div>
          </div>
          <div class="col-md-4 col-lg-4">
            <div
                class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100 border-radius-7"
                data-aos="fade-up" data-aos-duration="5500">
              <div class="circle-icon">
                <img src="../assets/icons/traffic.png" width="80" alt="promo" class="img-fluid"/>
              </div>
              <h5>Intelligent traffic control systems</h5>
              <p>With our intelligent traffic control system,you can get an accurate insight on your daily car
                movement.</p>
            </div>
          </div>
          <div class="col-md-4 col-lg-4">
            <div
                class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100 border-radius-7"
                data-aos-duration="6000" data-aos="fade-up">
              <div class="circle-icon">
                <img src="../assets/icons/wallet.png" width="80" alt="promo" class="img-fluid"/>
              </div>
              <h5>Digital wallet systems</h5>
              <p>With our digital wallet, you can make payment for waste bin and daily commute.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="contact" class="contact-us-section contact-us pt-100">
      <div class="container-fluid" style="padding-left: 0px;padding-right: 0px">
        <div class="container grid-editable-wrapper">
          <h2 class="grid-title">Need a Consultation?</h2>
        </div>
        <div class="grid-container colored-background" style="background-color: rgb(250, 250, 250);">
          <div class="container">
            <div class="contact-layout">
              <div class="p-contact-block__description"><p>Drop us a line! We are here to answer your questions
                24/7.</p>
              </div>
              <div class="row">
                <div class="col-md-8">
                  <form class="contact-form" v-on:submit.prevent="submit">
                    <div class="row">
                      <div class="col-md-3 form-spacing" style="margin-bottom: 1px !important;">
                        <div class="form-group">
                          <input type="text" name="name" v-model="form.name" class="form-control" id="name"
                                 placeholder="Full Name">
                        </div>

                      </div>
                      <div class="col-md-3 form-spacing" style="margin-bottom: 1px !important;">
                        <div class="form-group">
                          <input type="text" name="company" v-model="form.company" class="form-control" id="company"
                                 placeholder="Company Name">
                        </div>

                      </div>
                      <div class="col-md-3 form-spacing" style="margin-bottom: 1px !important;">
                        <div class="form-group">
                          <input type="text" name="email" v-model="form.email" class="form-control" id="email"
                                 placeholder="Work Email">
                        </div>

                      </div>
                      <div class="col-md-3 form-spacing" style="margin-bottom: 1px !important;">
                        <div class="form-group">
                          <input type="text" name="phone" v-model="form.number" class="form-control" id="phone"
                                 placeholder="Work Phone">
                        </div>

                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                                                                 <textarea name="message" v-model="form.message"
                                                                           id="message" class="form-control"
                                                                           rows="7" cols="25"
                                                                           placeholder="How can we help you?"></textarea>
                        </div>

                      </div>
                      <div class="col-sm-12 mt-3">
                        <button type="submit" class="btn solid-btn el-center" id="btnContactUs" :disabled="loading">
                          Send us an email <i class="fa fa-spinner fa-spin" v-if="loading"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                  <div class="alert alert-success mt-3" v-if="success"> Message sent successfully</div>
                  <div class="alert alert-danger mt-3" v-if="error">Ops, something went wrong while sending this
                    message. Try
                    again
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="contact-information-editable">
                        <p class="contact-information-title">
                          Our contact details
                        </p>
                        <div class="contact-information-phone">
                          <i
                              class="p-10 ti-mobile color-2  rounded-circle"></i>
                          <a
                              class="underline" href="tel:+2349067300000"
                              style="padding-top: 12px;color: #717171;">+2349067300000</a>
                        </div>
<!--                        <div class="contact-information-email">-->
<!--                          <i class="p-10 ti-email"></i>-->
<!--                          <a itemprop="email" style="padding-top: 12px;color: #717171;"-->
<!--                             class="underline" href="mailto:hardwebsolutions@gmail.com">hardwebsolutions@gmail.com</a>-->
<!--                        </div>-->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </section>
    <!--contact us section end-->
  </div>
  <!--body content wrap end-->
</template>

<script>
import axios from "axios";

export default {
  name: "Index",
  data: function () {
    return {
      form: {
        name: '',
        email: '',
        number: '',
        company: '',
        message: ''
      },
      success: null,
      error: null,
      loading: false,
      backImg: "url('../assets/img/hero-bg-2.jpg') no-repeat center center / cover"
    }
  },
  methods: {
    submit: function () {
      this.error = false
      this.success = false
      if (this.form.name === '' || this.form.message === '' || this.form.email === '') {
        this.error = false
        let self = this
        setTimeout(function () {
          self.error = ''
        }, 5000)
        return false
      }
      this.loading = true
      axios.post(`${process.env.VUE_APP_ENDPOINT}hardweb/send_mail/`, this.form).then(() => {
        this.loading = false
        this.error = false
        this.success = true
        this.reset()
        this.form = {
          name: '',
          email: '',
          number: '',
          company: '',
          message: ''
        }
      }).catch(() => {
        this.loading = false
        this.success = false
        this.error = true
        this.reset()
      })

    },
    reset: function () {
      let self = this
      setTimeout(function () {
        self.success = false
        self.error = false
      }, 5000)
    }
  },
  created() {
  }
}
</script>

<style scoped>
#home {
  background: url('../assets/img/hero-bg-2.jpg') no-repeat center center / cover !important;
}

#contact__image {
  height: 332px;
  width: auto;
  position: relative;
  top: 10px
}

.floating {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

.weight-500 {
  font-weight: 500;
}

@media only screen and (max-width: 640px) {
  .section-top-sm {
    margin-top: 33px;
  }

  #contact__image {
    height: 146px;
  }

  .hide-sm {
    display: none !important;
  }

  .-mb2 {
    margin-bottom: -2px !important;
  }
}
</style>