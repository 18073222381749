<template>
  <span>
    <Header/>
    <Index/>
    <Footer/>
  </span>
</template>

<script>
import Header from "../components/Header";
import Index from "./Index";
import Footer from "../components/Footer";

export default {
  name: "Main",
  components: {Footer, Index, Header}
}
</script>

<style scoped>

</style>