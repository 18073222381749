<template>
  <!--footer section start-->
  <footer class="footer-section">
    <!--footer top start-->
    <div class="footer-top gradient-bg">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-lg-6 col-12">
                <div class="footer-nav-wrap text-white mb-0 mb-md-4 mb-lg-0">
                  <a class="d-block" href="#"><img src="../assets/img/logo.png" alt="footer logo" width="100"
                                                   class="img-fluid mb-1"></a>
                  <p>Building Digital systems and services to enhance African lives..</p>
                  <div style="margin-bottom: 17px
;"><a href="mailto:hardwebsolutions@gmail.com" style="" class="rounded"><span
                      class="pd-10 ti-email white-bg color-2 shadow rounded-circle"></span><span class="text-desc">hardwebsolutions@gmail.com</span></a>
                  </div>
                  <div style="margin-bottom: 17px
;"><a style="" class="rounded"><span
                      class="pd-10 ti-mobile white-bg color-2 shadow rounded-circle"></span><span class="text-desc">09067300000, 08180000352</span></a>
                  </div>


                </div>
              </div>

              <div class="col-lg-6 col-md-4 col-12">
                <div class="footer-nav-wrap text-white">
                  <h5 class="text-light footer-head">Newsletter</h5>
                  <p>Subscribe our newsletter to get our update. We don't send span email to you.</p>
                  <form action="#" class="newsletter-form mt-3">
                    <div class="input-group">
                      <input type="email" class="form-control" id="subscribe-email" placeholder="Enter your email"
                             required="">
                      <div class="input-group-append">
                        <button class="btn solid-btn subscribe-btn btn-hover" type="submit">
                          Subscribe
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--footer top end-->

    <!--footer copyright start-->
    <div class="footer-bottom gray-light-bg py-3">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6 col-lg-6"><p class="copyright-text pb-0 mb-0">Copyrights © {{ year }}. All
            rights reserved by
            <a href="/" target="_blank" style="font-weight: bolder;">Hardweb Solutions</a></p></div>
          <div class="col-md-6 col-lg-6">
            <ul class="list-unstyled social-list mb-0">
              <li class="list-inline-item"><a href="#" class="rounded"><span
                  class="ti-facebook white-bg color-2 shadow rounded-circle"></span></a></li>
              <li class="list-inline-item"><a href="#" class="rounded"><span
                  class="ti-twitter white-bg color-2 shadow rounded-circle"></span></a></li>
              <li class="list-inline-item"><a href="#" class="rounded"><span
                  class="ti-linkedin white-bg color-2 shadow rounded-circle"></span></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!--footer copyright end-->
  </footer>
  <!--footer section end-->
</template>

<script>
export default {
  name: "Footer",
  data: function () {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>

<style scoped>
.text-desc {
  margin-left: 12px;
  color: #fff;
  font-weight: 500;
}

.pd-10 {
  padding: 10px !important;
}

.social-list {
  text-align: right;
}

@media only screen and (max-width: 640px) {
  .social-list {
  text-align: center;
}
}
</style>